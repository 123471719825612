.markerClusterSmall {
    border-radius: 20px;
    font-size: 22px;
    color: #000000;
    font-weight: 900;
    text-transform: uppercase;
    background-color: rgb(210, 124, 124);
 }
 .markerClusterMedium {
    border-radius: 40px;
    font-size: 32px;
    color: #000;
    font-weight: 900;
    text-transform: uppercase;
    background-color: yellow;
 }
 .markerClusterLarge {
    border-radius: 70px;
    font-size: 0.6vw;
    color: #fff;
    font-weight: 900;
    font-family: Open Sans;
    text-transform: uppercase;
    background-color: yellowgreen;
 }
 .markerClusterLargeXL {
    border-radius: 70px;
    font-size: 0.6vw;
    color: #000;
    font-weight: 900;
    font-family: Open Sans;
    text-transform: uppercase;
    background-color: green;
 }
 .markerClusterLabel {
    justify-content: center;
    align-items: center;
    align-self: center;
} 

#detailPosition {
   position: absolute;
   z-index: 9995;
   top: 20px;
   right: 25px;
   width: 290px;
   height: 30px;
   background: white;
   font-weight: 900;
}
.box-title {
   display: inline-block;
   padding-left: 5px;
}
#btn-box-tool {
   padding: 2px 15px;
   position: relative;
   left: 40px ;
}
#buttonMinusContainer {
   display: inline;
}
#infoContent {
   padding-left: 5px;
   background-color: rgba(255, 255, 255, 0.7)
}

#infoContent p strong {
   font-weight: 900;
}